import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="loop.gif" />
        Atrocious LLC<br />
        <small>🍳 <a href="https://iykykkitchens.com" style={{color: "white"}}>Keus</a></small> 
      </header>
    </div>
  );
}

export default App;
